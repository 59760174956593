.modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #a39493bd;
	justify-content: center;
	align-items: center;

	transition: ease-in-out 0.3s !important;

}

.close {
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 20px;
	cursor: pointer;
}

.iframe-map {
	@media (max-width: 600px) {
		width: 328px !important;
		height: 262px !important;
	}
}

#copyCCI,
#copyAccount {
	@media (max-width: 600px) {
		right: 10px !important;
	}
}

#btnFalabella {
	@media (max-width: 600px) {
		width: 100% !important;
	}
}

#modalTableGift .modal-content {

	@media (max-height: 768px) {
		max-height: calc(100vh - 40px) !important;

	}
}