@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
@font-face {
  font-family: lg;
  src: url("lg.f8edcdbb.woff2") format("woff2"), url("lg.253cca28.ttf") format("truetype"), url("lg.f801b1a8.woff") format("woff"), url("lg.c9542252.svg#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.lg-icon {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: lg !important;
}

.lg-container {
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.lg-next, .lg-prev {
  color: #999;
  cursor: pointer;
  z-index: 1084;
  background-color: #00000073;
  border: none;
  border-radius: 2px;
  outline: none;
  margin-top: -10px;
  padding: 8px 10px 9px;
  font-size: 22px;
  display: block;
  position: absolute;
  top: 50%;
}

.lg-next.disabled, .lg-prev.disabled {
  cursor: default;
  opacity: 0 !important;
}

.lg-next:hover:not(.disabled), .lg-prev:hover:not(.disabled) {
  color: #fff;
}

.lg-single-item .lg-next, .lg-single-item .lg-prev {
  display: none;
}

.lg-next {
  right: 20px;
}

.lg-next:before {
  content: "";
}

.lg-prev {
  left: 20px;
}

.lg-prev:after {
  content: "";
}

@keyframes lg-right-end {
  0% {
    left: 0;
  }

  50% {
    left: -30px;
  }

  100% {
    left: 0;
  }
}

@keyframes lg-left-end {
  0% {
    left: 0;
  }

  50% {
    left: 30px;
  }

  100% {
    left: 0;
  }
}

.lg-outer.lg-right-end .lg-object {
  animation: .3s lg-right-end;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  animation: .3s lg-left-end;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-media-overlap .lg-toolbar {
  background-image: linear-gradient(0deg, #0000, #0006);
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  text-align: center;
  will-change: color;
  -o-transition: color .2s linear;
  box-shadow: none;
  background: none;
  border: none;
  outline: none;
  width: 50px;
  height: 47px;
  padding: 10px 0;
  font-size: 24px;
  line-height: 27px;
  transition: color .2s linear;
  text-decoration: none !important;
}

.lg-toolbar .lg-icon.lg-icon-18 {
  font-size: 18px;
}

.lg-toolbar .lg-icon:hover {
  color: #fff;
}

.lg-toolbar .lg-close:after {
  content: "";
}

.lg-toolbar .lg-maximize {
  font-size: 22px;
}

.lg-toolbar .lg-maximize:after {
  content: "";
}

.lg-toolbar .lg-download:after {
  content: "";
}

.lg-sub-html {
  color: #eee;
  text-align: center;
  z-index: 1080;
  opacity: 0;
  -o-transition: opacity .2s ease-out 0s;
  padding: 10px 40px;
  font-size: 16px;
  transition: opacity .2s ease-out;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}

.lg-sub-html p {
  margin: 5px 0 0;
  font-size: 12px;
}

.lg-sub-html a {
  color: inherit;
}

.lg-sub-html a:hover {
  text-decoration: underline;
}

.lg-media-overlap .lg-sub-html {
  background-image: linear-gradient(#0000, #0009);
}

.lg-item .lg-sub-html {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.lg-error-msg {
  color: #999;
  font-size: 14px;
}

.lg-counter {
  color: #999;
  vertical-align: middle;
  height: 47px;
  padding-top: 12px;
  padding-left: 20px;
  font-size: 16px;
  display: inline-block;
}

.lg-closing .lg-toolbar, .lg-closing .lg-prev, .lg-closing .lg-next, .lg-closing .lg-sub-html {
  opacity: 0;
  -webkit-transition: -webkit-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  -moz-transition: -moz-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  -o-transition: -o-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  transition: transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-img-wrap, body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-video-cont {
  opacity: 0;
  will-change: transform, opacity;
  transform: scale3d(.5, .5, .5);
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s, opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  transition: transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-img-wrap, body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-video-cont {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.lg-icon:focus-visible {
  color: #fff;
  border-radius: 3px;
  outline: 1px dashed #fff9;
}

.lg-toolbar .lg-icon:focus-visible {
  outline-offset: -5px;
  border-radius: 8px;
}

.lg-group:after {
  content: "";
  clear: both;
  display: table;
}

.lg-container {
  outline: none;
  display: none;
}

.lg-on {
  scroll-behavior: unset;
}

.lg-overlay-open {
  overflow: hidden;
}

.lg-toolbar, .lg-prev, .lg-next, .lg-pager-outer, .lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1);
  -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s, opacity .25s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1);
}

.lg-show-in .lg-toolbar, .lg-show-in .lg-prev, .lg-show-in .lg-next, .lg-show-in .lg-pager-outer, .lg-show-in.lg-hide-sub-html .lg-sub-html {
  opacity: 1;
}

.lg-show-in .lg-hide-items .lg-prev {
  opacity: 0;
  transform: translate3d(-10px, 0, 0);
}

.lg-show-in .lg-hide-items .lg-next {
  opacity: 0;
  transform: translate3d(10px, 0, 0);
}

.lg-show-in .lg-hide-items .lg-toolbar {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}

.lg-show-in .lg-hide-items.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}

.lg-outer {
  z-index: 1050;
  text-align: left;
  opacity: .001;
  will-change: auto;
  -o-transition: opacity .15s ease 0s;
  outline: none;
  width: 100%;
  height: 100%;
  transition: opacity .15s;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.lg-outer * {
  box-sizing: border-box;
}

.lg-outer.lg-zoom-from-image, .lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-prev-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-next-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  opacity: 1;
  transition-duration: 0s !important;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-content {
  position: absolute;
  inset: 0;
}

.lg-outer .lg-inner {
  -o-transition: opacity 0s;
  white-space: nowrap;
  width: 100%;
  transition: opacity;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.lg-outer .lg-item {
  display: none !important;
}

.lg-outer .lg-item:not(.lg-start-end-progress) {
  background: url("loading.9e763859.gif") center no-repeat;
}

.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide, .lg-outer.lg-css .lg-current {
  display: inline-block !important;
}

.lg-outer .lg-item, .lg-outer .lg-img-wrap {
  text-align: center;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
}

.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
  content: "";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.lg-outer .lg-img-wrap {
  white-space: nowrap;
  font-size: 0;
  position: absolute;
  inset: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-object {
  vertical-align: middle;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: inline-block;
  position: relative;
}

.lg-outer .lg-empty-html.lg-sub-html, .lg-outer .lg-empty-html .lg-sub-html {
  display: none;
}

.lg-outer.lg-hide-download .lg-download {
  opacity: .75;
  pointer-events: none;
}

.lg-outer .lg-first-slide .lg-dummy-img {
  position: absolute;
  top: 50%;
  left: 50%;
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-components {
  opacity: 1;
  transform: translate3d(0, 0%, 0);
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  opacity: 1;
  transition: opacity .2s ease-out .15s;
}

.lg-backdrop {
  z-index: 1040;
  opacity: 0;
  will-change: auto;
  -o-transition: opacity .333s ease-in 0s;
  background-color: #000;
  transition: opacity .333s ease-in;
  position: fixed;
  inset: 0;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -o-transition: none 0s ease 0s !important;
  transition: none !important;
}

.lg-css3.lg-use-css3 .lg-item {
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -o-transition: opacity .1s ease 0s;
  transition: opacity .1s;
}

.lg-css3.lg-use-css3 .lg-item.lg-start-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(.175, .885, .32, 1.275);
  -o-transition: -o-transform 1s cubic-bezier(.175, .885, .32, 1.275) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(.175, .885, .32, 1.275);
  transition: transform 1s cubic-bezier(.175, .885, .32, 1.275);
}

.lg-css3.lg-use-css3 .lg-item.lg-start-end-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1);
  -o-transition: -o-transform 1s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, .25, 1);
  transition: transform 1s cubic-bezier(0, 0, .25, 1);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
  transition: transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
}

.lg-container {
  display: none;
}

.lg-container.lg-show {
  display: block;
}

.lg-container.lg-dragging-vertical .lg-backdrop {
  transition-duration: 0s !important;
}

.lg-container.lg-dragging-vertical .lg-css3 .lg-item.lg-current {
  opacity: 1;
  transition-duration: 0s !important;
}

.lg-inline .lg-backdrop, .lg-inline .lg-outer {
  position: absolute;
}

.lg-inline .lg-backdrop {
  z-index: 1;
}

.lg-inline .lg-outer {
  z-index: 2;
}

.lg-inline .lg-maximize:after {
  content: "";
}

.lg-components {
  will-change: transform;
  -webkit-transition: -webkit-transform .35s ease-out;
  -o-transition: -o-transform .35s ease-out 0s;
  -moz-transition: -moz-transform .35s ease-out;
  z-index: 1080;
  transition: transform .35s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate3d(0, 100%, 0);
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  transition-duration: 0s !important;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  -webkit-transition: -webkit-transform .5s cubic-bezier(.12, .415, .01, 1.19);
  -o-transition: -o-transform .5s cubic-bezier(.12, .415, .01, 1.19) 0s;
  -moz-transition: -moz-transform .5s cubic-bezier(.12, .415, .01, 1.19);
  transition: transform .5s cubic-bezier(.12, .415, .01, 1.19);
}

.lg-outer.lg-use-transition-for-zoom.lg-zoom-drag-transition .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  -webkit-transition: -webkit-transform .8s cubic-bezier(0, 0, .25, 1);
  -o-transition: -o-transform .8s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform .8s cubic-bezier(0, 0, .25, 1);
  transition: transform .8s cubic-bezier(0, 0, .25, 1);
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image, .lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img {
  backface-visibility: hidden;
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform .5s cubic-bezier(.12, .415, .01, 1.19), opacity .15s !important;
  -o-transition: -o-transform .5s cubic-bezier(.12, .415, .01, 1.19) 0s, opacity .15s !important;
  -moz-transition: -moz-transform .5s cubic-bezier(.12, .415, .01, 1.19), opacity .15s !important;
  transition: transform .5s cubic-bezier(.12, .415, .01, 1.19), opacity .15s !important;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.no-transition, .lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.no-transition {
  transition: none !important;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition, .lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.reset-transition {
  max-width: none !important;
  max-height: none !important;
  top: 50% !important;
  left: 50% !important;
  transform: scale3d(1, 1, 1)translate3d(-50%, -50%, 0) !important;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition-x, .lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.reset-transition-x {
  max-width: none !important;
  max-height: none !important;
  top: 0 !important;
  left: 50% !important;
  transform: scale3d(1, 1, 1)translate3d(-50%, 0, 0) !important;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition-y, .lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.reset-transition-y {
  max-width: none !important;
  max-height: none !important;
  top: 50% !important;
  left: 0% !important;
  transform: scale3d(1, 1, 1)translate3d(0, -50%, 0) !important;
}

.lg-icon.lg-zoom-in:after {
  content: "";
}

.lg-actual-size .lg-icon.lg-zoom-in {
  opacity: .5;
  pointer-events: none;
}

.lg-icon.lg-actual-size {
  font-size: 20px;
}

.lg-icon.lg-actual-size:after {
  content: "";
}

.lg-icon.lg-zoom-out {
  opacity: .5;
  pointer-events: none;
}

.lg-icon.lg-zoom-out:after {
  content: "";
}

.lg-zoomed .lg-icon.lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer[data-lg-slide-type="video"] .lg-zoom-in, .lg-outer[data-lg-slide-type="video"] .lg-actual-size, .lg-outer[data-lg-slide-type="video"] .lg-zoom-out, .lg-outer[data-lg-slide-type="iframe"] .lg-zoom-in, .lg-outer[data-lg-slide-type="iframe"] .lg-actual-size, .lg-outer[data-lg-slide-type="iframe"] .lg-zoom-out, .lg-outer.lg-first-slide-loading .lg-zoom-in, .lg-outer.lg-first-slide-loading .lg-actual-size, .lg-outer.lg-first-slide-loading .lg-zoom-out {
  opacity: .75;
  pointer-events: none;
}

.lg-outer .lg-thumb-outer {
  float: left;
  background-color: #0d0a0a;
  width: 100%;
  max-height: 350px;
  overflow: hidden;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb, .lg-outer .lg-thumb-outer.lg-rebuilding-thumbnails .lg-thumb {
  transition-duration: 0s !important;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-middle {
  text-align: center;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-left {
  text-align: left;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-right {
  text-align: right;
}

.lg-outer.lg-single-item .lg-thumb-outer {
  display: none;
}

.lg-outer .lg-thumb {
  vertical-align: middle;
  height: 100%;
  margin-bottom: -5px;
  padding: 5px 0;
  display: inline-block;
}

@media (width >= 768px) {
  .lg-outer .lg-thumb {
    padding: 10px 0;
  }
}

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  will-change: border-color;
  border-radius: 2px;
  height: 100%;
  margin-bottom: 5px;
  overflow: hidden;
}

@media (width >= 768px) {
  .lg-outer .lg-thumb-item {
    -o-transition: border-color .25s ease;
    border: 2px solid #fff;
    border-radius: 4px;
    transition: border-color .25s;
  }
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}

.lg-outer .lg-thumb-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer .lg-toggle-thumb:after {
  content: "";
}

.lg-outer.lg-animate-thumb .lg-thumb {
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
}

.modal {
  background-color: #a39493bd;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  transition: all .3s ease-in-out !important;
}

.close {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
}

@media (width <= 600px) {
  .iframe-map {
    width: 328px !important;
    height: 262px !important;
  }

  #copyCCI, #copyAccount {
    right: 10px !important;
  }

  #btnFalabella {
    width: 100% !important;
  }
}

@media (height <= 768px) {
  #modalTableGift .modal-content {
    max-height: calc(100vh - 40px) !important;
  }
}

:root {
  --pilsener-100: #feffee;
  --pilsener-200: #fcffdd;
  --pilsener-300: #f9ffba;
  --pilsener-400: #f7ff98;
  --pilsener-500: #f4ff75;
  --pilsener-600: #f1ff53;
  --pilsener-700: #c1cc42;
  --pilsener-800: #919932;
  --pilsener-900: #606621;
  --pilsener-1000: #303311;
  --cadmium-yellow-100: #fffee6;
  --cadmium-yellow-200: #fffdcc;
  --cadmium-yellow-300: #fffb99;
  --cadmium-yellow-400: #fff866;
  --cadmium-yellow-500: #fff633;
  --cadmium-yellow-600: #fff400;
  --cadmium-yellow-700: #ccc300;
  --cadmium-yellow-800: #999200;
  --cadmium-yellow-900: #666200;
  --cadmium-yellow-1000: #333100;
  --spectra-yeloow-100: #fef8e8;
  --spectra-yeloow-200: #fdf0d0;
  --spectra-yeloow-300: #fbe2a1;
  --spectra-yeloow-400: #fad373;
  --spectra-yeloow-500: #f8c544;
  --spectra-yeloow-600: #f6b615;
  --spectra-yeloow-700: #c59211;
  --spectra-yeloow-800: #946d0d;
  --spectra-yeloow-900: #624908;
  --spectra-yeloow-1000: #624908;
  --orange-bell-pepper-100: #fff3ec;
  --orange-bell-pepper-200: #ffe7d9;
  --orange-bell-pepper-300: #ffcfb3;
  --orange-bell-pepper-400: #ffb68e;
  --orange-bell-pepper-500: #ff9e68;
  --orange-bell-pepper-600: #ff8642;
  --orange-bell-pepper-700: #cc6b35;
  --orange-bell-pepper-800: #995028;
  --orange-bell-pepper-900: #66361a;
  --orange-bell-pepper-1000: #331b0d;
  --vibrant-orange-100: #fff1e9;
  --vibrant-orange-200: #ffe2d2;
  --vibrant-orange-300: #ffc6a6;
  --vibrant-orange-400: #ffa979;
  --vibrant-orange-500: #ff8d4d;
  --vibrant-orange-600: #ff7020;
  --vibrant-orange-700: #cc5a1a;
  --vibrant-orange-800: #994313;
  --vibrant-orange-900: #662d0d;
  --vibrant-orange-1000: #331606;
  --dynamite-100: #ffece9;
  --dynamite-200: #ffd8d3;
  --dynamite-300: #ffb2a7;
  --dynamite-400: #ff8b7c;
  --dynamite-500: #ff6550;
  --dynamite-600: #ff3e24;
  --dynamite-700: #cc321d;
  --dynamite-800: #992516;
  --dynamite-900: #66190e;
  --dynamite-1000: #330c07;
  --red-stop-100: #ffe9e9;
  --red-stop-200: #ffd4d4;
  --red-stop-300: #fea8a8;
  --red-stop-400: #fe7d7d;
  --red-stop-500: #fd5151;
  --red-stop-600: #fd2626;
  --red-stop-700: #ca1e1e;
  --red-stop-800: #981717;
  --red-stop-900: #650f0f;
  --red-stop-1000: #330808;
  --in-the-red-100: #ffe8eb;
  --in-the-red-200: #ffd2d7;
  --in-the-red-300: #ffa4af;
  --in-the-red-400: #ff7787;
  --in-the-red-500: #ff495f;
  --in-the-red-600: #ff1c37;
  --in-the-red-700: #cc162c;
  --in-the-red-800: #991121;
  --in-the-red-900: #660b16;
  --in-the-red-1000: #33060b;
  --scarlet-cattleya-orchid-100: #f9e6ee;
  --scarlet-cattleya-orchid-200: #f2ccdc;
  --scarlet-cattleya-orchid-300: #e599b9;
  --scarlet-cattleya-orchid-400: #d96696;
  --scarlet-cattleya-orchid-500: #cc3373;
  --scarlet-cattleya-orchid-600: #bf0050;
  --scarlet-cattleya-orchid-700: #990040;
  --scarlet-cattleya-orchid-800: #730030;
  --scarlet-cattleya-orchid-900: #4c0020;
  --scarlet-cattleya-orchid-1000: #260010;
  --fuchsia-fever-100: #ffedf5;
  --fuchsia-fever-200: #ffdbea;
  --fuchsia-fever-300: #ffb8d6;
  --fuchsia-fever-400: #fe94c1;
  --fuchsia-fever-500: #fe71ad;
  --fuchsia-fever-600: #fe4d98;
  --fuchsia-fever-700: #cb3e7a;
  --fuchsia-fever-800: #982e5b;
  --fuchsia-fever-9h00: #661f3d;
  --fuchsia-fever-1000: #330f1e;
  --pisces-vivid-amethyst-100: #f7edfd;
  --pisces-vivid-amethyst-200: #eedafb;
  --pisces-vivid-amethyst-300: #ddb5f7;
  --pisces-vivid-amethyst-400: #cd90f4;
  --pisces-vivid-amethyst-500: #bc6bf0;
  --pisces-vivid-amethyst-600: #ab46ec;
  --pisces-vivid-amethyst-700: #8938bd;
  --pisces-vivid-amethyst-800: #672a8e;
  --pisces-vivid-amethyst-900: #441c5e;
  --pisces-vivid-amethyst-1000: #220e2f;
  --sea-grape-100: #ebe6f7;
  --sea-grape-200: #d7ccef;
  --sea-grape-300: #af99df;
  --sea-grape-400: #8866ce;
  --sea-grape-500: #6033be;
  --sea-grape-600: #3800ae;
  --sea-grape-700: #2d008b;
  --sea-grape-800: #220068;
  --sea-grape-900: #160046;
  --sea-grape-1000: #0b0023;
  --cloisonne-100: #e6f1f7;
  --cloisonne-200: #cce3ef;
  --cloisonne-300: #99c6df;
  --cloisonne-400: #66aad0;
  --cloisonne-500: #338dc0;
  --cloisonne-600: #0071b0;
  --cloisonne-700: #005a8d;
  --cloisonne-800: #00446a;
  --cloisonne-900: #002d46;
  --cloisonne-1000: #001723;
  --dilvant-100: #1f77fb;
  --dilvant-200: #1c78fd;
  --dilvant-300: #2565e8;
  --dilvant-400: #1f77f9;
  --bayern-blue-100: #e6f6fb;
  --bayern-blue-200: #ccecf6;
  --bayern-blue-300: #99d9ee;
  --bayern-blue-400: #66c7e5;
  --bayern-blue-500: #33b4dd;
  --bayern-blue-600: #00a1d4;
  --bayern-blue-700: #0081aa;
  --bayern-blue-800: #00617f;
  --bayern-blue-900: #004055;
  --bayern-blue-1000: #00202a;
  --electron-blue-100: #e6f2fb;
  --electron-blue-200: #cce5f7;
  --electron-blue-300: #99cbef;
  --electron-blue-400: #66b0e7;
  --electron-blue-500: #3396df;
  --electron-blue-600: #007cd7;
  --electron-blue-700: #0063ac;
  --electron-blue-800: #004a81;
  --electron-blue-900: #003256;
  --electron-blue-1000: #00192b;
  --fern-green-100: #e6f3ed;
  --fern-green-200: #cce7da;
  --fern-green-300: #99cfb5;
  --fern-green-400: #66b891;
  --fern-green-500: #33a06c;
  --fern-green-600: #008847;
  --fern-green-700: #006d39;
  --fern-green-800: #00522b;
  --fern-green-900: #00361c;
  --fern-green-1000: #001b0e;
  --candy-green-100: #ebfbe6;
  --candy-green-200: #d7f6cc;
  --candy-green-300: #afed99;
  --candy-green-400: #87e466;
  --candy-green-500: #5fdb33;
  --candy-green-600: #37d200;
  --candy-green-700: #2ca800;
  --candy-green-800: #217e00;
  --candy-green-900: #165400;
  --candy-green-1000: #0b2a00;
  --delicious-dill-100: #f2fae6;
  --delicious-dill-200: #e5f4cc;
  --delicious-dill-300: #cbea99;
  --delicious-dill-400: #b1df66;
  --delicious-dill-500: #97d533;
  --delicious-dill-600: #7dca00;
  --delicious-dill-700: #64a200;
  --delicious-dill-800: #4b7900;
  --delicious-dill-900: #325100;
  --delicious-dill-1000: #192800;
  --black-slug-100: #eae9e8;
  --black-slug-200: #d6d2d0;
  --black-slug-300: #ada5a1;
  --black-slug-400: #837973;
  --black-slug-500: #5a4c44;
  --black-slug-600: #311f15;
  --black-slug-700: #271911;
  --black-slug-800: #1d130d;
  --black-slug-900: #140c08;
  --black-slug-1000: #0a0604;
  --stone-cold-100: #eee;
  --stone-cold-200: #ddd;
  --stone-cold-300: #bbb;
  --stone-cold-400: #9a9a9a;
  --stone-cold-500: #787878;
  --stone-cold-600: #565656;
  --stone-cold-700: #454545;
  --stone-cold-800: #343434;
  --stone-cold-900: #222;
  --stone-cold-1000: #111;
  --stone-cold-1100: #131314;
  --celandine-green-100: #f8f8f7;
  --celandine-green-200: #f1f2ef;
  --celandine-green-300: #e3e5df;
  --celandine-green-400: #d4d7d0;
  --celandine-green-500: #c6cac0;
  --celandine-green-600: #b8bdb0;
  --celandine-green-700: #93978d;
  --celandine-green-800: #93978d;
  --celandine-green-900: #4a4c46;
  --celandine-green-1000: #4a4c46;
  --dire-wolf-100: #e9e9e9;
  --dire-wolf-200: #d4d4d4;
  --dire-wolf-300: #a9a9a9;
  --dire-wolf-400: #7d7d7d;
  --dire-wolf-500: #525252;
  --dire-wolf-600: #272727;
  --dire-wolf-700: #1f1f1f;
  --dire-wolf-800: #171717;
  --dire-wolf-900: #101010;
  --dire-wolf-1000: #080808;
}

.has-text-weight-extrabold {
  font-weight: 800;
}

.has-text-weight-black {
  font-family: 900;
}

.is-size-08 {
  font-size: 3.8rem;
}

.is-size-05 {
  font-size: 4.8rem;
}

@font-face {
  font-family: Palatino Linotype;
  src: url("palatinolinotype_roman.36998693.ttf");
}

@font-face {
  font-family: Palatino Linotype Bold;
  src: url("palatinolinotype_bold.7a42f401.ttf");
}

*, :before, :after {
  box-sizing: inherit;
  border: 0;
  margin: 0;
  padding: 0;
}

h1 {
  font-family: Metropolis-semibold, sans-serif;
  font-weight: bold;
}

h2 {
  font-family: Metropolis-semibold, sans-serif;
}

p {
  font-family: Metropolis, sans-serif;
}

body::-webkit-scrollbar {
  background-color: #0000;
  width: .2em;
}

button, #copyCCI, #copyAccount {
  cursor: pointer;
  transition: all .3s ease-in-out !important;
}

button:hover, #musicBtn:hover {
  background-color: #998379 !important;
}

#copyCCI:hover, #copyAccount:hover {
  color: #6d5da3 !important;
}

#initial {
  max-height: 1000px;
  transition: transform 1s cubic-bezier(.68, -.55, .27, 1.55), opacity .5s ease-in-out, max-height 2s ease-in-out;
  overflow: hidden;
}

.slideUp {
  opacity: 0;
  max-height: 0;
  transform: translateY(-100%);
}

.home-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: hidden;
}

@media (height <= 799px) {
  .home-container {
    overflow-y: scroll;
  }
}

.home-container .home-box {
  gap: 24px;
}

@media (width <= 1023px) {
  .home-container .home-box {
    text-align: center;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .home-container .home-box img {
    margin: 0 auto 50px;
  }
}

@media (width <= 830px) and (width >= 500px) {
  .home-container .home-box {
    gap: 0;
    margin-top: 248px;
  }
}

@media (width <= 500px) {
  .home-container .home-box {
    gap: 0;
    margin-top: 38px;
  }
}

.header-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 0;
  left: 0;
  overflow-y: hidden;
}

.header-container .home-box {
  gap: 24px;
}

@media (width <= 1023px) {
  .header-container .home-box {
    flex-direction: column;
  }
}

@media (width <= 830px) and (width >= 500px) {
  .header-container .home-box {
    gap: 0;
    margin-top: 248px;
  }
}

.container__primary {
  max-width: 1344px;
  margin: 0 auto;
}

@media (width <= 1407px) and (width >= 1216px) {
  .container__primary {
    max-width: 85%;
  }
}

@media (width <= 1215px) {
  .container__primary {
    max-width: 90%;
  }
}

@media (width <= 768px) {
  .container__primary {
    max-width: 75%;
  }
}

@media (width <= 568px) {
  .container__primary {
    max-width: 85%;
  }
}

.header {
  background-repeat: no-repeat;
  background-size: contain;
}

@media (width <= 992px) {
  .profile-pic {
    flex-direction: column;
    align-items: center;
  }
}

@media (width <= 600px) {
  .pic {
    object-fit: contain;
    height: 275px;
  }
}

@media (width >= 600px) and (width <= 992px) {
  .pic {
    object-fit: contain;
    width: 346px;
    height: 356px;
  }
}

@media (width <= 600px) {
  .img-boyfriends-name {
    width: 270px;
    height: 144px;
  }
}

@media (width <= 991px) and (width >= 600px) {
  .img-boyfriends-name {
    width: 352.94px;
    height: 195px;
  }
}

.two_columns {
  grid-template-columns: 50% 50%;
  height: 100%;
  display: grid;
}

@media (width < 1024px) {
  .two_columns {
    grid-template-columns: 100%;
  }
}

.profile {
  border-radius: 50%;
  width: 240px;
  height: 240px;
  margin: 0 auto;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

@media (width <= 768px) {
  .profile {
    width: 160px;
    height: 160px;
    bottom: -31%;
  }
}

@media (width >= 769px) and (width <= 1023px) {
  .profile {
    width: 190px;
    height: 190px;
    bottom: -16%;
  }
}

.header-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

@media (width <= 768px) {
  .header-content {
    background-position: center;
    height: 88%;
  }
}

@media (width >= 1024px) and (width <= 1350px) {
  .header-content {
    gap: 20px;
    position: absolute;
    top: 0;
    right: -96%;
  }
}

@media (width >= 1350px) {
  .header-content {
    gap: 20px;
    position: absolute;
    top: 0;
    right: -77%;
  }
}

@media (width >= 760px) and (width <= 1200px) {
  .header-content .header-logo {
    height: 42px;
  }
}

@media (width >= 1200px) {
  .header-content .header-logo {
    width: 600px;
    height: 48px;
  }
}

@media (width <= 500px) {
  .slider-description-srv {
    flex-direction: column;
    gap: 5px !important;
  }

  .slider-description-srv .line {
    width: 60px;
    transform: rotate(180deg);
    height: 4px !important;
  }
}

#container__seciton {
  column-count: 2;
  gap: 25px;
}

@media (width < 1024px) {
  #container__seciton {
    column-count: 1;
    margin-bottom: 0 !important;
  }
}

.is-hover-btn:hover {
  filter: brightness(.9);
}

@media (width <= 1150px) {
  .mobile-block {
    flex-direction: column;
    gap: 10px;
    display: flex;
  }
}

.circle {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.slick-track {
  gap: 23px !important;
  display: flex !important;
}

@media (width > 980px) {
  .slick-track {
    width: 100% !important;
  }
}

.slick-track a {
  height: 100%;
  width: 100% !important;
}

.slick-track a img {
  object-fit: cover;
  height: 100% !important;
}

@media (width <= 1024px) {
  #lightgallery a {
    display: none;
  }

  #lightgallery a:nth-last-child(-n+2) {
    height: 131px;
    display: block;
  }
}

@media (width <= 769px) {
  #box_content {
    width: auto !important;
  }
}

.ceremonials-content {
  background-position: -6% 0;
  background-repeat: no-repeat;
}

@media (width <= 1200px) {
  .ceremonials-content {
    background-image: unset;
  }
}

.aboutPartyContent {
  background-color: #fbf9f2;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 9%;
}

@media (width <= 1200px) {
  .aboutPartyContent {
    background-image: unset;
  }
}

@media (width <= 1215px) {
  .padrinos-sec {
    flex-direction: column;
    display: flex;
  }
}

@media (width <= 538px) {
  .padrinos-sec .rings {
    width: 186px;
    height: 240px;
  }
}

@media (width <= 768px) and (width >= 539px) {
  .padrinos-sec .rings {
    width: 233px;
    height: 300px;
  }
}

@media (width <= 768px) and (width >= 538px) {
  .padrinos-sec .box {
    padding: 5% 15% !important;
  }
}

@media (width <= 768px) {
  .padrinos-sec .second-box {
    margin-top: 32px;
  }
}

.vector-count-down {
  z-index: -1;
  width: 100%;
  position: absolute;
  top: 37%;
  left: 0;
}

@media (width <= 600px) {
  .vector-count-down {
    position: unset;
    width: 0;
    height: 0 !important;
  }
}

.vector-count-down img {
  display: flex;
}

@media (width <= 991px) {
  .vector-count-down img {
    display: none;
  }
}

.count-down {
  max-width: 436px;
}

@media (width <= 600px) {
  .count-down {
    width: 100%;
    max-width: 100%;
  }
}

.content-itinerary-info {
  width: 300px !important;
}

@media (width <= 600px) {
  .content-itinerary-info {
    width: 171px !important;
  }
}

.autoplay {
  height: 100%;
}

.autoplay a img {
  object-fit: cover;
}

.music-button {
  z-index: 10;
  background-color: #eedcc4;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  position: fixed;
  top: 5%;
  right: 7%;
  box-shadow: 0 1px 3px 1px #00000026, 0 1px 2px #0000004d;
}

.music-button img {
  width: 20px;
  height: 30px;
}

.music-button hr {
  position: absolute;
}

.music-button .pause {
  border-bottom: 3px solid #998379;
  width: 30px;
  top: -2px;
  left: 10px;
  transform: rotate(45deg);
  box-shadow: 0 1px 3px 1px #f7edfd, 0 1px 2px #f7edfd;
}

@media (width >= 1024px) {
  .title-home {
    margin-top: 1% !important;
  }
}

.purple-flower-half {
  top: 25%;
}

@media (width <= 600px) {
  .purple-flower-half {
    width: 74px;
    top: 19%;
  }
}

.flower-bottom-right {
  width: 174px;
  position: fixed;
  bottom: -11%;
  right: -4%;
}

@media (width <= 765px) {
  .flower-bottom-right {
    width: auto;
    bottom: -134px;
    right: -69px;
  }
}

@media (width <= 991px) and (width >= 765px) {
  .flower-bottom-right {
    width: 170px;
    bottom: -10%;
    right: -8%;
  }
}

.flower-bottom-center {
  position: fixed;
  bottom: -66px;
  right: 254px;
}

@media (width <= 600px) {
  .flower-bottom-center {
    width: 154px;
    bottom: -81px;
    right: 14vw;
  }
}

@media (width <= 1025px) and (width >= 600px) {
  .flower-bottom-center {
    width: 154px;
    bottom: -74px;
    right: 38vw;
  }
}

.group-flowers {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (width <= 600px) {
  .group-flowers {
    width: 81px;
  }
}

#goToIndex {
  z-index: 2;
}

@media (width <= 600px) {
  #goToIndex {
    width: 155px;
  }
}

.count-down-left-flower {
  height: 439px;
  position: absolute;
  left: 0;
}

@media (width >= 991px) and (width <= 1150px) {
  .count-down-left-flower {
    height: 300px;
  }
}

.gallery-left-flower {
  z-index: 2;
  height: 439px;
  position: absolute;
  top: 2271px;
  left: 0;
}

@media (width >= 991px) and (width <= 1150px) {
  .gallery-left-flower {
    height: 300px;
  }
}

.gallery-right-flower {
  z-index: 2;
  height: 360px;
  position: absolute;
  top: 2292px;
  right: 0;
}

@media (width >= 786px) and (width <= 1150px) {
  .gallery-right-flower {
    height: 300px;
  }
}

.footer-flowers {
  position: absolute;
  bottom: 0;
}

@media (width <= 1407px) and (width >= 1216px) {
  .footer-flowers {
    height: 438px;
  }
}

@media (width <= 1215px) {
  .footer-flowers {
    height: 438px;
  }
}

@media (width <= 768px) {
  .footer-flowers {
    height: 206px;
  }
}

@media (width <= 568px) {
  .footer-flowers {
    height: 94px;
  }
}

@media (width < 768px) {
  #itinerary {
    flex-direction: column;
  }
}

@media (width <= 600px) {
  #goToIndex {
    width: 155px;
    margin: 0 auto;
  }
}

.bg-card {
  background-image: url("bg-card.fd1365a9.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@media (width < 1200px) {
  .bg-card {
    background-image: url("bg-mobile.be479a55.png");
  }
}

.align-direction {
  margin-bottom: 200px;
}

@media (width < 600px) {
  .align-direction {
    flex-direction: column;
    margin-bottom: 0;
  }
}

/*# sourceMappingURL=index.a17fd125.css.map */
