@import 'https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css';
@import './styles/paletas';
@import './styles/fonts';
@import './../node_modules/lightgallery/css/lightgallery.css';
@import './../node_modules/lightgallery/css/lg-zoom.css';
@import './../node_modules/lightgallery/css/lg-thumbnail.css';
@import url('./modal.scss');



@font-face {
	font-family: 'Palatino Linotype';
	src: url('./assets/fonts/palatinolinotype_roman.ttf');
}

@font-face {
	font-family: 'Palatino Linotype Bold';
	src: url('./assets/fonts/palatinolinotype_bold.ttf');
}


*,
*:before,
*:after {
	box-sizing: inherit;
	margin: 0;
	padding: 0;
	border: 0;
}

h1 {
	// font-family: 'Inter', sans-serif;
	font-family: 'Metropolis-semibold', sans-serif;

	font-weight: bold;
}

h2 {
	font-family: 'Metropolis-semibold', sans-serif;
}

p {
	font-family: 'Metropolis', sans-serif;
}

body::-webkit-scrollbar {
	width: 0.2em;
	background-color: transparent;
}

button,
#copyCCI,
#copyAccount {
	cursor: pointer;
	transition: ease-in-out 0.3s !important;
}

button:hover {
	background-color: #998379 !important;
}

#musicBtn:hover {
	background-color: #998379 !important;
}

#copyCCI:hover,
#copyAccount:hover {
	color: #6d5da3 !important;
}

#initial {
	transition: transform 1s cubic-bezier(0.68, -0.55, 0.27, 1.55),
		opacity 0.5s ease-in-out, max-height 2s ease-in-out;
	overflow: hidden;
	max-height: 1000px;
}

.slideUp {
	transform: translateY(-100%);
	opacity: 0;
	max-height: 0;
}

.home-container {
	overflow-y: hidden;
	position: fixed;
	left: 0;
	top: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (max-height: 799px) {
		overflow-y: scroll;
	}

	.home-box {
		gap: 24px;

		@media (max-width: 1023px) {
			flex-direction: column-reverse;
			justify-content: center;
			text-align: center;

			img {
				margin: 0 auto 50px;
			}
		}

		@media (max-width: 830px) and (min-width: 500px) {
			margin-top: 248px;
			gap: 0;
		}

		@media (max-width: 500px) {
			margin-top: 38px;
			gap: 0;
		}
	}
}

.header-container {
	overflow-y: hidden;
	left: 0;
	top: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	// @media (max-height: 799px) {
	//   overflow-y: scroll;
	// }

	.home-box {
		gap: 24px;

		@media (max-width: 1023px) {
			flex-direction: column;
		}

		@media (max-width: 830px) and (min-width: 500px) {
			margin-top: 248px;
			gap: 0;
		}
	}
}

.container__primary {
	max-width: 1344px;
	margin: 0 auto;

	@media (max-width: 1407px) and (min-width: 1216px) {
		max-width: 85%;
	}

	@media (max-width: 1215px) {
		max-width: 90%;
	}

	@media (max-width: 768px) {
		max-width: 75%;
	}

	@media (max-width: 568px) {
		max-width: 85%;
	}
}

.header {
	background-size: contain;
	background-repeat: no-repeat;
}

.profile-pic {
	@media (max-width: 992px) {
		flex-direction: column;
		align-items: center;
	}
}

.pic {
	@media (max-width: 600px) {
		// width: 150px;
		height: 275px;
		object-fit: contain;
	}

	@media (min-width: 600px) and (max-width: 992px) {
		width: 346px;
		height: 356px;
		object-fit: contain;
	}
}

.img-boyfriends-name {
	@media (max-width: 600px) {
		width: 270px;
		height: 144px;
	}

	@media (max-width: 991px) and (min-width: 600px) {
		width: 352.94px;
		height: 195px;
	}
}

.two_columns {
	display: grid;
	grid-template-columns: 50% 50%;
	height: 100%;

	@media (width< 1024px) {
		grid-template-columns: 100%;
	}
}

.profile {
	margin: 0 auto;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 10px;

	border-radius: 50%;
	width: 240px;
	height: 240px;

	@media (max-width: 768px) {
		width: 160px;
		height: 160px;

		bottom: -31%;
	}

	@media (min-width: 769px) and (max-width: 1023px) {
		width: 190px;
		height: 190px;
		bottom: -16%;
	}
}

.header-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	height: 100%;

	@media (max-width: 768px) {
		background-position: center;
		height: 88%;
	}

	@media (min-width: 1024px) and (max-width: 1350px) {
		gap: 20px;
		position: absolute;
		right: -96%;
		top: 0;
	}

	@media (min-width: 1350px) {
		gap: 20px;
		position: absolute;
		right: -77%;
		top: 0;
	}

	.header-logo {
		@media (min-width: 760px) and (max-width: 1200px) {
			height: 42px;
		}

		@media (min-width: 1200px) {
			height: 48px;
			width: 600px;
		}
	}
}

.slider-description-srv {
	@media (max-width: 500px) {
		flex-direction: column;
		gap: 5px !important;

		.line {
			transform: rotate(180deg);
			height: 4px !important;
			width: 60px;
			// border-left: 7px solid var(--dilvant-300);
		}
	}
}

#container__seciton {
	column-count: 2;
	gap: 25px;

	@media (width< 1024px) {
		column-count: 1;
		margin-bottom: 0 !important;
	}
}

.is-hover-btn {
	&:hover {
		filter: brightness(0.9);
	}
}

.mobile-block {
	@media (max-width: 1150px) {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}

.circle {
	width: 45px;
	height: 45px;
	border-radius: 50%;
}

.slick-track {
	display: flex !important;
	// flex-direction: column;
	gap: 23px !important;

	@media (width> 980px) {
		width: 100% !important;
	}

	a {
		width: 100% !important;
		height: 100%;

		img {
			height: 100% !important;
			object-fit: cover;
		}
	}
}

#lightgallery {
	@media (width <=1024px) {
		a {
			display: none;

			&:nth-last-child(-n + 2) {
				display: block;
				height: 131px;
			}
		}
	}
}

#box_content {
	@media (width <=769px) {
		width: auto !important;
	}
}

.ceremonials-content {
	// background-image: url(./assets/flowes-vector.png);
	background-repeat: no-repeat;
	background-position: -6% top;

	@media (max-width: 1200px) {
		background-image: unset;
	}
}

.aboutPartyContent {
	// background-image: url(./assets/party-bg.png);
	// transform: rotate(180deg);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 9%;
	background-color: #fbf9f2;

	@media (max-width: 1200px) {
		background-image: unset;
	}
}

.padrinos-sec {
	@media (max-width: 1215px) {
		display: flex;
		flex-direction: column;
	}

	.rings {
		@media (max-width: 538px) {
			width: 186px;
			height: 240px;
		}

		@media (max-width: 768px) and (min-width: 539px) {
			width: 233px;
			height: 300px;
		}
	}

	.box {
		@media (max-width: 768px) and (min-width: 538px) {
			padding: 5% 15% !important;
		}
	}

	.second-box {
		@media (max-width: 768px) {
			margin-top: 32px;
		}
	}
}

.vector-count-down {
	position: absolute;
	top: 37%;
	left: 0;

	width: 100%;
	z-index: -1;

	@media (max-width: 600px) {
		position: unset;
		width: 0;
		height: 0 !important;
	}

	img {
		display: flex;

		@media (max-width: 991px) {
			display: none;
		}
	}
}

.count-down {
	max-width: 436px;

	@media (max-width: 600px) {
		max-width: 100%;
		width: 100%;
	}
}

.content-itinerary-info {
	width: 300px !important;

	@media (max-width: 600px) {
		width: 171px !important;
	}
}

.autoplay {
	height: 100%;

	a img {
		object-fit: cover;
	}
}

.music-button {
	position: fixed;
	right: 7%;
	top: 5%;
	z-index: 10;

	background-color: #eedcc4;
	border-radius: 50%;

	height: 48px;
	width: 48px;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;

	img {
		width: 20px;
		height: 30px;
	}

	hr {
		position: absolute;
	}

	.pause {
		top: -2px;
		left: 10px;
		width: 30px;
		border-bottom: 3px solid #998379;
		transform: rotate(45deg);
		box-shadow: 0px 1px 3px 1px #f7edfd, 0px 1px 2px 0px #f7edfd;
		// transition: all 0.5s;
	}
}

.title-home {
	@media (min-width: 1024px) {
		margin-top: 1% !important;
	}
}

.purple-flower-half {
	top: 25%;

	@media (max-width: 600px) {
		width: 74px;
		top: 19%;
	}
}

.flower-bottom-right {
	position: fixed;
	bottom: -11%;
	right: -4%;
	width: 174px;

	@media (max-width: 765px) {
		width: auto;
		bottom: -134px;
		right: -69px;
	}

	@media (max-width: 991px) and (min-width: 765px) {
		bottom: -10%;
		right: -8%;
		width: 170px;
	}
}

.flower-bottom-center {
	position: fixed;
	right: 254px;
	bottom: -66px;

	@media (max-width: 600px) {
		right: 14vw;
		bottom: -81px;
		width: 154px;
	}

	@media (max-width: 1025px) and (min-width: 600px) {
		right: 38vw;
		bottom: -74px;
		width: 154px;
	}
}

.group-flowers {
	position: absolute;
	left: 0;
	bottom: 0;

	@media (max-width: 600px) {
		width: 81px;
	}
}

#goToIndex {
	z-index: 2;

	@media (max-width: 600px) {
		width: 155px;
	}
}

.count-down-left-flower {
	position: absolute;
	left: 0;
	height: 439px;

	@media (min-width: 991px) and (max-width: 1150px) {
		height: 300px;
	}
}

.gallery-left-flower {
	position: absolute;
	left: 0;
	top: 2271px;
	z-index: 2;
	height: 439px;

	@media (min-width: 991px) and (max-width: 1150px) {
		height: 300px;
	}
}

.gallery-right-flower {
	position: absolute;
	right: 0;
	top: 2292px;
	z-index: 2;
	height: 360px;

	@media (min-width: 786px) and (max-width: 1150px) {
		height: 300px;
	}
}

.footer-flowers {
	position: absolute;
	bottom: 0;

	@media (max-width: 1407px) and (min-width: 1216px) {
		height: 438px;
	}

	@media (max-width: 1215px) {
		height: 438px;
	}

	@media (max-width: 768px) {
		height: 206px;
	}

	@media (max-width: 568px) {
		height: 94px;
	}
}

#itinerary {

	@media (width< 768px) {
		flex-direction: column;

	}
}

#goToIndex {
	@media (max-width: 600px) {
		width: 155px;
		margin: 0 auto;
	}
}

.bg-card {
	background-image: url('./assets/bg-card.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center;

	@media (width< 1200px) {
		// background-size: cover;
		background-image: url('./assets/bg-mobile.png');

	}
}

.align-direction {
	margin-bottom: 200px;

	@media (width< 600px) {

		margin-bottom: 0;

		flex-direction: column;
	}
}